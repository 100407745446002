// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

@import "./poppins.scss";
$typography-font-family : 'Poppins' !default;
$text-weights: (
    thin: 300,
    light: 400,
    regular: 600,
    medium: 700,
    bold: 800,
    bolder: 800) !default;

$primary   : #523a99;
$secondary : #26A69A;
$accent    : #9C27B0;

$dark      : #1D1D1D;
$dark-page : #121212;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;
